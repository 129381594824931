import React from "react"
import { graphql, StaticQuery, useStaticQuery } from "gatsby"
import PropTypes from 'prop-types'
import LinkCard from '../../../components/LinkCard'
import styles from './styles.module.scss'
import GridWrapper from "../../../components/GridWrapper";

const AreasOfInterestBlock = ({inContent}) => {
  const { allCategoryPage } = useStaticQuery(
      graphql`
          query {
              allCategoryPage(filter: {sections: {elemMatch: {section: {eq: "degrees"}}}}, sort: {fields: name, order: ASC}) {
                  edges {
                      node {
                          path
                          name
                          title
                      }
                  }
              }
          }
      `
  );

  const degrees = allCategoryPage.edges;

  return (
    <section className={styles.areasOfInterest}>

      { !inContent &&
        <>
          <h2 className={styles.title}>Explore Areas of Interest</h2>
          <h3 className={styles.subtitle}>Follow your interests and find the next step in your career.</h3>
        </>
      }

      <GridWrapper columns={inContent && 2}>
        {degrees.map( (item, index) => (
          <LinkCard
              title={item.node.name}
              subtitle={item.node.title}
              link={item.node.path}
              key={index}
          />
        ))}
      </GridWrapper>

    </section>
  )
};

AreasOfInterestBlock.propTypes = {
  data: PropTypes.object
};

export default AreasOfInterestBlock;
